






























































import { Component, Vue } from 'vue-property-decorator'
import UserService from '@/services/UserService'
import store from '@/store'
import Auth from '@/store/modules/Auth'
import { getModule } from 'vuex-module-decorators'
import FooterMobile from '@/components/FooterMobile.vue'

const AuthModule = getModule(Auth, store)

@Component({ components: { FooterMobile } })
export default class WithDraw extends Vue {
  private isChanged: boolean = false
  private activity_status: number = 4
  private errorMess: string = ''

  goToHome() {
    this.$router.push({ name: 'home' })
  }

  confirmChange() {
    let data = {
      activity_status: this.activity_status,
      not_check_belong_group: true
    }
    UserService.updateAccountStatus(data)
      .then(response => {
        if (response.status === 200) {
          this.isChanged = true
          AuthModule.SET_TOKEN_NULL()
          // setTimeout(() => {
          //   this.$router.replace({ name: 'login' }).catch(() => {})
          // }, 3000)
        }
      })
      .catch(error => {
        if (
          error.response.status === 422 ||
          error.response.data.message === 'user_is_only_group_admin_exist'
        ) {
          this.openModalError(
            this.$t('modal_show_profile.can_not_withdraw').toString()
          )
        } else if (
          error.response.status === 401 &&
          error?.response?.data?.status_code === 'USER_NOT_BE_LONG_TO_GROUP'
        ) {
          this.isChanged = true
          AuthModule.SET_TOKEN_NULL()
        } else {
          this.openModalError(this.$t('common.message.update_fail').toString())
        }
      })
  }

  openModalError(mess: string) {
    this.errorMess = mess
    this.$bvModal.show('modal-error')
  }
}
